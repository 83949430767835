
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91claimLine_93xuTFza4zdiMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue?macro=true";
import { default as index8q9KF5VihXMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue?macro=true";
import { default as _91claimId_93NPIZILduI4Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue?macro=true";
import { default as _91rid_93cXG0myCSS8Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue?macro=true";
import { default as _91connectorId_93i1YP7rvS1fMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as app_45statuswN5ecvZm6XMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/app-status.vue?macro=true";
import { default as indexQOXwgjDSrlMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/index.vue?macro=true";
import { default as auth_45callback852wOlvwczMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginj4HtcCjkEiMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusPp8tL2HSytMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: "connectorId",
    path: "/:connectorId()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue"),
    children: [
  {
    name: _91claimId_93NPIZILduI4Meta?.name,
    path: ":claimId()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue"),
    children: [
  {
    name: "connectorId-claimId-claimLine",
    path: ":claimLine()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue")
  },
  {
    name: "connectorId-claimId",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue")
  }
]
  },
  {
    name: "connectorId-claim-lab-claimId-rid",
    path: "claim-lab/:claimId()/:rid()",
    meta: _91rid_93cXG0myCSS8Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue")
  }
]
  },
  {
    name: "app-status",
    path: "/app-status",
    meta: app_45statuswN5ecvZm6XMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/app-status.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/index.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callback852wOlvwczMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginj4HtcCjkEiMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statusPp8tL2HSytMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  }
]