<script setup lang="ts">
import type { Api } from '@rialtic/api'

const props = defineProps<{
  connectorId: string
  claim: Api.ProcessedClaimWithInsights
  lineNumber: string
  insightId: string
}>()

const { data: influencing } = useApiFetch<Api.InfluencingClaimLine[]>(
  () =>
    `/api/connector/${props.connectorId}/insight/${props.insightId}/influencing-claims`,
  {
    lazy: true,
    server: false,
  },
)

const { sortedInfluencing, setSortState, sortState } = useInfluencingClaims(influencing, props.claim?.id)
</script>

<template>
  <section class="space-y-2">
    <h2 class="font-medium">Influencing Claim Lines</h2>
    <div
      v-if="sortedInfluencing.length"
      class="border-1 max-h-87 overflow-auto rounded-lg border-neutral-300"
    >
      <UiInfluencingClaimLines
        :claim="claim!"
        :hide-end-message="true"
        :influencing-claims="sortedInfluencing"
        :line-number="lineNumber"
        :sort-options="sortState"
        :sticky="false"
        @sort:state="setSortState"
      />
    </div>
    <span v-else class="inline-block text-sm text-neutral-700">
      The outcome was not influenced by other claim lines.
    </span>
  </section>
</template>
