<script setup lang="ts">
import type { Api } from '@rialtic/api'
import { formatDisplayDate } from '../../composables/date'

const props = withDefaults(
  defineProps<{
    claim: Api.ProcessedClaimWithInsights
    showHeader?: boolean
  }>(),
  {
    showHeader: true,
  },
)

const additionalIds = computed(
  () => props.claim?.additionalDetails?.additionalIds || null,
)
const billingName = computed(
  () => props.claim?.provider?.practitioner?.name?.family?.toLowerCase(),
)
</script>

<template>
  <section>
    <div v-if="showHeader" class="mb-4">
      <p class="h3 mb-2 text-neutral-900">Claim Header</p>
    </div>
    <div class="grid grid-cols-[10rem_1fr] items-start gap-4">
      <UiClaimInfo title="Patient name">
        <Protected>
          <p class="capitalize">
            {{ claim.patientName?.toLowerCase() }}
          </p>
        </Protected>
      </UiClaimInfo>

      <UiClaimInfo title="Patient birthdate">
        <Protected>
          {{ formatDisplayDate(claim.patient?.birthDate) || '&ndash;' }}
        </Protected>
      </UiClaimInfo>

      <UiClaimInfo title="Patient sex">
        <Protected class="capitalize">
          {{ claim.patient?.gender || '&ndash;' }}
        </Protected>
      </UiClaimInfo>

      <UiClaimInfo title="Date(s) of service">
        {{ formatDisplayDate(claim.servicedPeriod?.start) }} &ndash;
        {{ formatDisplayDate(claim.servicedPeriod?.end) }}
      </UiClaimInfo>

      <UiClaimInfo title="Member ID">
        <Protected :disabled="!claim.subscriberId">
          {{ claim.subscriberId || '&ndash;' }}
        </Protected>
      </UiClaimInfo>

      <UiClaimInfo title="Member group ID">
        <Protected :disabled="!claim.memberGroupId">
          {{ claim.memberGroupId || '&ndash;' }}
        </Protected>
      </UiClaimInfo>

      <UiClaimInfo title="Billing provider ID">
        <p v-if="billingName" class="capitalize">
          {{ billingName }}
        </p>
        <p class="whitespace-nowrap">
          TIN:
          {{ claim.billingProviderIdentifiers?.ein || '&ndash;' }}
        </p>
        <p>
          NPI:
          {{ claim.billingProviderIdentifiers?.npi || '&ndash;' }}
        </p>
        <template v-if="additionalIds" title="Billing provider IDs">
          <p v-if="additionalIds.altId">Alt ID: {{ additionalIds.altId }}</p>
          <p v-if="additionalIds.network">
            Network: {{ additionalIds.network }}
          </p>
          <p v-if="additionalIds.contract">
            Contract: {{ additionalIds.contract }}
          </p>
        </template>
      </UiClaimInfo>

      <div class="subtitle-2">Specialty Codes</div>
      <div class="body-2 flex flex-wrap gap-1">
        <!-- <div class="flex flex-wrap gap-1"> -->
        <div
          v-for="codeSet in claim.additionalDetails?.specialtyCodes"
          class="body-2 min-w-[46px] rounded-md bg-neutral-100 p-1 px-2"
        >
          <p class="text-primary-900">
            {{ codeSet.raw }}
          </p>
          <UiTooltip v-if="codeSet.description">
            <template #activator>
              <slot>
                <div
                  class="body-2 text-secondary-700 hover:text-secondary-800 active:text-secondary-900 inline-block"
                >
                  {{ codeSet.value }}
                </div>
              </slot>
            </template>
            <div class="p-2">
              <p class="body-2 max-w-prose">
                <span class="subtitle-2">
                  {{ codeSet.value }}
                </span>
                - {{ codeSet.description }}
              </p>
            </div>
          </UiTooltip>
          <p v-else class="text-secondary-700">
            {{ codeSet.value }}
          </p>
        </div>
        <!-- </div> -->
      </div>

      <UiClaimInfo title="Total charged">
        ${{ claim.total?.value.toFixed(2) || '&ndash;' }}
      </UiClaimInfo>

      <UiClaimInfo title="Prior auth #">
        {{ claim.insurance?.[0].preAuthRef?.[0] || '&ndash;' }}
      </UiClaimInfo>

      <UiClaimInfo
        v-if="claim.additionalDetails?.ltssIndicator"
        title="LTSS indicator"
      >
        {{ claim.additionalDetails.ltssIndicator }}
      </UiClaimInfo>

      <UiClaimInfo
        v-if="claim.additionalDetails?.parIndicator"
        title="Par indicator"
      >
        {{ claim.additionalDetails.parIndicator }}
      </UiClaimInfo>

      <div class="subtitle-2">Diagnosis Codes</div>
      <div class="body-2">
        <span v-for="(code, index) in claim.diagnosisCodes">
          <UiRefDataCode :code="code.codeableConcept" model="Diagnosis" />
          <span
            v-if="index < claim.diagnosisCodes.length - 1"
            class="-ml-1 pl-px"
          >
            ,&nbsp;
          </span>
        </span>
      </div>
    </div>
</section>
</template>
