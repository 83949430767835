import type { Api } from '@rialtic/api'

export type DiagnosisKey =
  | 'diagnosis2'
  | 'diagnosis3'
  | 'diagnosis4'
  | 'diagnosis5'
export type ModifierKey = 'modifier1' | 'modifier2' | 'modifier3' | 'modifier4'

export type FormattedInfluencingClaim = Api.InfluencingClaimLine &
  Record<ModifierKey, string | null> &
  Record<DiagnosisKey, string | null> & {
    id: string
    billingProviderId: string | null | undefined
    claimId: string
    diagnosis1: string
    startDate: Date | null
    endDate: Date | null
    netValue: number
    patientBirthdate: Date | undefined
    patientGender: string | undefined
    placeOfService: string
    preAuthRef: any
    procedureCode: string
    quantityValue: number
    renderingProviderId: string
    totalValue: number
    secondaryHighlight: boolean
  }
export type sortKey = keyof FormattedInfluencingClaim
export type sortValue = 'up' | 'down' | undefined

export const useInfluencingClaims = (
  influencing: Ref<Api.InfluencingClaimLine[] | null>,
  claimId: string | undefined,
) => {
  const sortState = ref({
    column: 'startDate' as sortKey,
    sort: 'down' as sortValue,
  })

  const formattedInfluencing = computed(() => {
    if (!influencing.value) {
      return []
    }

    return influencing.value.map((item) => {
      const modifierKeys: ModifierKey[] = [
        'modifier1',
        'modifier2',
        'modifier3',
        'modifier4',
      ]
      const modifierItems = modifierKeys.reduce(
        (state, key, index) => {
          state[key] = item?.modifier?.[index]?.coding?.[0]?.code || null
          return state
        },
        {} as Record<ModifierKey, string | null>,
      )

      const diagnosisKeys: DiagnosisKey[] = [
        'diagnosis2',
        'diagnosis3',
        'diagnosis4',
        'diagnosis5',
      ]
      const diagnosisItems = diagnosisKeys.reduce(
        (state, key, index) => {
          state[key] =
            item?.diagnosisAdditional?.[index]?.codeableConcept || null
          return state
        },
        {} as Record<DiagnosisKey, string | null>,
      )

      return {
        ...item,
        ...modifierItems,
        ...diagnosisItems,
        id: `${item.claim?.id}:${item.lineNumber}`,
        billingProviderId: item.claim.billingProviderIdentifiers?.ein,
        claimId: item?.claim.id,
        diagnosis1: item.diagnosis1.codeableConcept,
        startDate: item?.servicedPeriod.start,
        endDate: item?.servicedPeriod.end,
        netValue: item?.net?.value,
        patientBirthdate: item.claim.patient?.birthDate,
        patientGender: item.claim.patient?.gender,
        placeOfService: item?.locationCodeableConcept?.coding?.[0]?.code,
        preAuthRef: item.claim.insurance?.[0].preAuthRef?.[0],
        procedureCode: item?.productOrService?.coding?.[0]?.code,
        quantityValue: item?.quantity?.value,
        renderingProviderId:
          item.renderingProviderNpi || item.renderingProviderTin,
        totalValue: item.claim.total?.value,
        secondaryHighlight: item.claim.id === claimId,
      } as FormattedInfluencingClaim
    })
  })

  const setSortState = (state: (typeof sortState)['value']) => {
    sortState.value = state
  }

  const sortedInfluencing = computed(() => {
    if (!sortState.value.sort) return formattedInfluencing.value

    return [...formattedInfluencing.value].sort((a, b) => {
      const aLineNumber = (
        sortState.value.sort === 'up'
          ? a.lineNumber
          : Number.MAX_SAFE_INTEGER - a.lineNumber
      )
        .toString()
        .padStart(4, '0')
      const aValue = a[sortState.value.column] + a.claimId + aLineNumber
      if (aValue == null) {
        return 1
      }
      const bLineNumber = (
        sortState.value.sort === 'up'
          ? b.lineNumber
          : Number.MAX_SAFE_INTEGER - b.lineNumber
      )
        .toString()
        .padStart(4, '0')
      const bValue = b[sortState.value.column] + b.claimId + bLineNumber
      if (bValue == null) {
        return -1
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const result = aValue.localeCompare(bValue)
        return sortState.value.sort === 'down' ? result * -1 : result
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        const result = bValue - aValue
        return sortState.value.sort === 'down' ? result * -1 : result
      }

      return 0
    })
  })

  return {
    formattedInfluencing,
    sortedInfluencing,
    setSortState,
    sortState,
  }
}
